// Sidebar navigation

.sidebar-nav {
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0;
  margin-bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;

  .nav-title {
    padding: var(--#{$prefix}sidebar-nav-title-padding-y) var(--#{$prefix}sidebar-nav-title-padding-x);
    margin-top: var(--#{$prefix}sidebar-nav-title-margin-top);
    font-size: 80%;
    font-weight: 700;
    color: var(--#{$prefix}sidebar-nav-title-color);
    text-transform: uppercase;
    @include transition($sidebar-nav-title-transition);
  }

  .nav-link {
    display: flex;
    flex: 1;
    align-items: center;
    padding: var(--#{$prefix}sidebar-nav-link-padding-y) var(--#{$prefix}sidebar-nav-link-padding-x);
    color: var(--#{$prefix}sidebar-nav-link-color);
    text-decoration: none;
    white-space: nowrap;
    background: var(--#{$prefix}sidebar-nav-link-bg);
    border: var(--#{$prefix}sidebar-nav-link-border);
    @include border-radius(var(--#{$prefix}sidebar-nav-link-border-radius));
    @include transition($sidebar-nav-link-transition);

    &.active {
      color: var(--#{$prefix}sidebar-nav-link-active-color);
      background: var(--#{$prefix}sidebar-nav-link-active-bg);

      .nav-icon {
        color: var(--#{$prefix}sidebar-nav-link-active-icon-color);
      }
    }

    &.disabled {
      color: var(--#{$prefix}sidebar-nav-link-disabled-color);
      pointer-events: none;
      cursor: not-allowed;
      background: transparent;

      .nav-icon {
        color: var(--#{$prefix}sidebar-nav-link-disabled-icon-color);
      }

      &:hover {
        color: var(--#{$prefix}sidebar-nav-link-disabled-color);

        .nav-icon {
          color: var(--#{$prefix}sidebar-nav-link-disabled-icon-color);
        }

        &.nav-dropdown-toggle::after {
          background-image: var(--#{$prefix}sidebar-nav-group-indicator-hover);
        }
      }
    }

    @media (hover: hover), (-ms-high-contrast: none) {
      &:hover {
        color: var(--#{$prefix}sidebar-nav-link-hover-color);
        text-decoration: none;
        background: var(--#{$prefix}sidebar-nav-link-hover-bg);

        .nav-icon {
          color: var(--#{$prefix}sidebar-nav-link-hover-icon-color);
        }

        &.nav-group-toggle::after {
          background-image: var(--#{$prefix}sidebar-nav-group-indicator-hover);
        }
      }
    }
  }

  .nav-icon {
    flex: 0 0 var(--#{$prefix}sidebar-nav-icon-width);
    height: var(--#{$prefix}sidebar-nav-icon-height);
    font-size: var(--#{$prefix}sidebar-nav-icon-font-size);
    color: var(--#{$prefix}sidebar-nav-link-icon-color);
    text-align: center;
    fill: currentcolor;
    @include transition(inherit);

    &:first-child {
      @include ltr-rtl("margin-left", calc(var(--#{$prefix}sidebar-nav-link-padding-x) * -1));
    }
  }
  // stylelint-disable-next-line selector-no-qualifying-type
  svg.nav-icon {
    overflow: hidden; // fix chrome 105+ width issue
  }

  .nav-group {
    position: relative;
    @include transition($sidebar-nav-group-transition);

    .nav-group-items {
      padding: var(--#{$prefix}sidebar-nav-group-items-padding-y) var(--#{$prefix}sidebar-nav-group-items-padding-x);
      overflow: hidden;
      @include transition($sidebar-nav-group-items-transition);
    }

    &:not(.show) .nav-group-items {
      display: none;
    }

    &.show {
      background: var(--#{$prefix}sidebar-nav-group-bg);

      .nav-group-toggle {
        color: var(--#{$prefix}sidebar-nav-group-toggle-show-color);
      }

      > .nav-group-toggle::after {
        transform: rotate(180deg);
      }

      + .show {
        margin-top: 1px;
      }
    }
  }

  .nav-group-toggle {
    cursor: pointer;

    &::after {
      display: block;
      flex: 0 12px;
      height: 12px;
      @include ltr-rtl("margin-left", auto);
      content: "";
      background-image: var(--#{$prefix}sidebar-nav-group-indicator);
      background-repeat: no-repeat;
      background-position: center;
      @include transition($sidebar-nav-group-indicator-transition);
    }
  }

  .nav-group-items {
    padding: 0;
    list-style: none;

    .nav-link {
      @include ltr-rtl("padding-left", var(--#{$prefix}sidebar-nav-icon-width));

      .nav-icon {
        @include ltr-rtl("margin-left", calc(var(--#{$prefix}sidebar-nav-icon-width) * -1));
      }
    }
  }

  &.compact,
  .compact {
    .nav-link {
      padding-top: calc(var(--#{$prefix}sidebar-nav-link-padding-y) * .5); // stylelint-disable-line function-disallowed-list
      padding-bottom: calc(var(--#{$prefix}sidebar-nav-link-padding-y) * .5); // stylelint-disable-line function-disallowed-list
    }
  }
}
